/* modify Bootstrap variables here */

@import 'core/bootstrap';

/* add additional CSS rules below */

/* GLOBAL STYLES
-------------------------------------------------- */
/* Padding below the footer and lighter body text */

body {
  padding-top: 3rem;
  padding-bottom: 3rem;
  color: #5a5a5a;
}

.btn {
  -webkit-appearance: none;
}
/* CUSTOMIZE THE CAROUSEL
-------------------------------------------------- */

/* Carousel base class */
.carousel {
  margin-bottom: 4rem;
}
/* Since positioning the image, we need to help out the caption */
.carousel-caption {
  bottom: 3rem;
  z-index: 10;
}

/* Declare heights because of positioning of img element */
.carousel-item {
  height: 32rem;
}
.carousel-item > img {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  height: 32rem;
}


/* MARKETING CONTENT
-------------------------------------------------- */

/* Center align the text within the three columns below the carousel */
.marketing .col-lg-4 {
  margin-bottom: 1.5rem;
  text-align: center;
}
.marketing h2 {
  font-weight: 400;
}
.marketing .col-lg-4 p {
  margin-right: .75rem;
  margin-left: .75rem;
}


/* Featurettes
------------------------- */

.featurette-divider {
  margin: 2rem 0; /* Space out the Bootstrap <hr> more */
}

/* Thin out the marketing headings */
.featurette-heading {
  font-weight: 300;
  line-height: 1;
  letter-spacing: -.05rem;
}


/* RESPONSIVE CSS
-------------------------------------------------- */

.featurette-heading {
    font-size: 50px;
  }

.sitelogo {
  width: 340px;
  margin-left: auto;
  margin-right: auto;
}

.pi-nav .navbar-nav .nav-link {
  color: #000;
  font-weight: 500;
  padding-right: 4rem;
}

.nav-item:hover {
  text-decoration: underline;
}

.navbar .navbar-collapse {
  text-align: center;
}

.mr-auto, .mx-auto {
    margin-right: auto !important;
    margin-left: 50px;
}

.tradecarousel {
  margin-bottom: 4rem;
  margin-top: 0;
}

.sitehead {
  margin-bottom: 148px;
}

.pgcontent {
  margin-top: 40px;
  text-align: center;
}

.footer {
  text-align: center;
}

.syno {
  margin-top: 200px;
}

.carousel {
  width: 100%
}

.contactinfo {
  text-align: center;
}

.showroomcrsl {
  margin: 0;
}

.flex-column { 
  max-width : 260px;
}

/* Team Photo Gallery styling */


.gallery-block .heading{
    margin-bottom: 50px;
    text-align: center;
}

.gallery-block .heading h2{
    font-weight: bold;
    font-size: 1.4rem;
    text-transform: uppercase;
}

.gallery-block.cards-gallery h6 {
  font-size: 17px;
  font-weight: bold; 
}

.gallery-block.cards-gallery .card{
  transition: 0.4s ease; 
}

.gallery-block.cards-gallery .card img {
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15); 
}

.gallery-block.cards-gallery .card-body {
  text-align: center; 
}

.gallery-block.cards-gallery .card-body p {
  font-size: 15px; 
}

.gallery-block.cards-gallery .card {
  margin-bottom: 30px; 
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
}

@media (min-width: 576px) {

  .gallery-block .transform-on-hover:hover {
      transform: translateY(-10px) scale(1.02);
      box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.15) !important; 
  }

}

@media (max-width: 767px) {
  .pi-nav .navbar-nav .nav-link {
    color: #000;
    font-weight: 500;
    padding-right: 0rem;
  }
}